// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-index-jsx": () => import("/opt/build/repo/src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-daily-life-jsx": () => import("/opt/build/repo/src/pages/daily-life.jsx" /* webpackChunkName: "component---src-pages-daily-life-jsx" */),
  "component---src-pages-economy-jsx": () => import("/opt/build/repo/src/pages/economy.jsx" /* webpackChunkName: "component---src-pages-economy-jsx" */),
  "component---src-pages-immigration-and-borders-jsx": () => import("/opt/build/repo/src/pages/immigration-and-borders.jsx" /* webpackChunkName: "component---src-pages-immigration-and-borders-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-labour-jsx": () => import("/opt/build/repo/src/pages/labour.jsx" /* webpackChunkName: "component---src-pages-labour-jsx" */),
  "component---src-pages-leave-promises-jsx": () => import("/opt/build/repo/src/pages/leave-promises.jsx" /* webpackChunkName: "component---src-pages-leave-promises-jsx" */),
  "component---src-pages-sovereignty-jsx": () => import("/opt/build/repo/src/pages/sovereignty.jsx" /* webpackChunkName: "component---src-pages-sovereignty-jsx" */),
  "component---src-pages-view-all-jsx": () => import("/opt/build/repo/src/pages/view-all.jsx" /* webpackChunkName: "component---src-pages-view-all-jsx" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

